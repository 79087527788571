import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import client from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const sanityClient = client({
  projectId: "up6j07zx",
  dataset: "australia-prod",
  useCdn: true,
  apiVersion: "2021-03-25",
});

export const imageBuilder = imageUrlBuilder(sanityClient).auto("format");

export function urlForImageRef(source?: SanityImageSource) {
  return imageBuilder.image(source ?? "");
}
