export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 767;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;
export const PAGE_INNER = 1440;

export const colors = {
  transparent: "transparent",
  primary: "#414950",
  white: "#fff",
  black: "#000",
  navy: "#003352",
  lightBlue: "#E0F0FF",
  blue: "#00639E",
  cream: "#F9F5F0",
  mint: "#DFF8EB",
  green: "#06765E",
  grey: "#E1E1E1",
  skyBlue: "#2EA3D4",
  orange: "#FF5A00",
  yellow: "#f5cc78",
  backgroundBlue: "#ecf4f3",
  teal: "#039499",
  textChatBackground: "#f9f9f9",
  textChatReceive: "#e5e5e5",
  textChatSend: "#f8e1ac",
  textChatTextColor: "#42494f",
  textChatResponseColor: "#9dc5c1",
  lightGrey: "#f2f2f2",
  maroon: "#741113",
  semiTransparent: "rgba(225, 225, 225, 0.6)",
  dashboardBackground: "#f6f9f9",
  red: "#FF0000",
  progressBackground: "#e6efee",
  closeIcon: "#f0d595",
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

export const routes = {
  app: "/app",
  account: "/app/account",
  dashboard: "/app/dashboard",
  orders: "/app/orders",
};

export const PRIMARY_COLOR = colors.primary;
export const SECONDARY_COLOR = colors.black;

export const FONT_BODY = "avenir";
export const FONT_BODY_WEIGHT = fontWeights.normal;
export const FONT_HEADER = "cocon";
export const FONT_HEADER_WEIGHT = fontWeights.bold;

export const fontSizes = {
  p: { default: 20, mobile: 16 },
  h0: { default: 55, mobile: 42 },
  h1: { default: 45, mobile: 32 },
  h2: { default: 35, mobile: 28 },
  h3: { default: 28, mobile: 22 },
  h4: { default: 25, mobile: 20 }, //Subheading
  h5: { default: 20, mobile: 16 },
  small: { default: 16, mobile: 14 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
  playButton: require("@static/assets/playButton.svg").default,
  sendAudio: require("@static/assets/messageSent.m4a").default,
  receiveAudio: require("@static/assets/messageReceived.m4a").default,
  sendIcon: require("@static/assets/send_icon.png").default,
  questionSendIcon: require("@static/assets/question_send_icon.png").default,
  gigidy: require("@static/assets/giggity.mp3").default,
  audioIcon: require("@static/assets/audio_play.png").default,
  accordionArrow: require("@static/assets/accordion-arrow.svg").default,
  pencil: require("@static/assets/pencil.svg").default,
  dashboardLogo: require("@static/assets/dashboard-logo.png").default,
  rocket: require("@static/assets/rocket.png").default,
  arrow: require("@static/assets/arrow.svg").default,
  fbIcon: require("@static/assets/fbIcon.svg").default,
  eagle: require("@static/assets/Personality-Eagle.svg").default,
  peacock: require("@static/assets/Personality-Peacock.svg").default,
  owl: require("@static/assets/Personality-Owl.svg").default,
  dove: require("@static/assets/Personality-Dove.svg").default,
  eagleNoArrow: require("@static/assets/eagle.png").default,
  peacockNoArrow: require("@static/assets/peacock.png").default,
  owlNoArrow: require("@static/assets/owl.png").default,
  doveNoArrow: require("@static/assets/dove.png").default,
  financial: require("@static/assets/financial.png").default,
  identity: require("@static/assets/identity.png").default,
  workReady: require("@static/assets/work-ready.png").default,
  investment: require("@static/assets/investment.png").default,
  accordionActive: require("@static/assets/Accordion-Active.svg").default,
  accordionActiveOrange: require("@static/assets/Accordion-Active-Orange.svg").default,
  workshopLock: require("@static/assets/Workshop-Lock.svg").default,
  workshopUnlock: require("@static/assets/Workshop-Unlock.svg").default,
  sectionComplete: require("@static/assets/section-complete.svg").default,
  sectionInProgress: require("@static/assets/section-in-progress.svg").default,
  sectionLocked: require("@static/assets/section-locked.svg").default,
  buttonIconStart: require("@static/assets/button-icon-start.svg").default,
  buttonIconRedo: require("@static/assets/button-icon-redo.svg").default,
  buttonIconResume: require("@static/assets/button-icon-resume.svg").default,
  showSidebar: require("@static/assets/show-sidebar.svg").default,
  dashboardCertificate: require("@static/assets/Certificate.svg").default,
  timer: require("@static/assets/timer.svg").default,
  starsOne: require("@static/assets/stars_one.png").default,
  starsTwo: require("@static/assets/stars_two.png").default,
  starsThree: require("@static/assets/stars_three.png").default,
  sectionMenuLock: require("@static/assets/MENU_LOCK_OVERLAY.png").default,
  sectionLockComplete: require("@static/assets/MENU_TICK_OVERLAY.png").default,
  mentorAudio: require("@static/assets/mentor_audio.png").default,
  mentorAudioMute: require("@static/assets/mentor_audio_mute.png").default,
  mentorAudioGrey: require("@static/assets/mentor_audio_grey.png").default,
  mentorAudioGreyStrikeout: require("@static/assets/mentor_audio_grey_strike.png").default,
  mentorAudioOrange: require("@static/assets/mentor_audio_orange.png").default,
  mentorAudioOrangeStrikeout: require("@static/assets/mentor_audio_strike.png").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.navy}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.navy}`,
  },
  baseWhite: {
    bg: `${colors.white}`,
    text: `${colors.navy}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.navy}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.white}`,
  },
  baseGreen: {
    bg: `${colors.green}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.green}`,
  },
  baseBlue: {
    bg: `${colors.blue}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colors.blue}`,
  },
  bordered: {
    bg: `${colors.transparent}`,
    text: `${colors.navy}`,
    border: `${colors.navy}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.navy}`,
  },
  borderedWhite: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.white}`,
  },
  borderedBlue: {
    bg: `${colors.transparent}`,
    text: `${colors.blue}`,
    border: `${colors.blue}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.blue}`,
    hoverText: `${colors.blue}`,
  },
  orange: {
    bg: `${colors.orange}`,
    text: `${colors.white}`,
    border: `${colors.yellow}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.orange}`,
    hoverText: `${colors.orange}`,
  },
  teal: {
    bg: `${colors.backgroundBlue}`,
    text: `${colors.teal}`,
    border: `${colors.yellow}`,
    hoverBg: `${colors.teal}`,
    hoverBorder: `${colors.yellow}`,
    hoverText: `${colors.white}`,
  },
  reverseOrange: {
    bg: `${colors.white}`,
    text: `${colors.orange}`,
    border: `${colors.yellow}`,
    hoverBg: `${colors.orange}`,
    hoverBorder: `${colors.yellow}`,
    hoverText: `${colors.white}`,
  },
  borderedOrange: {
    bg: `transparent`,
    text: `${colors.orange}`,
    border: `${colors.orange}`,
    hoverBg: `${colors.orange}`,
    hoverBorder: `${colors.orange}`,
    hoverText: `${colors.white}`,
  },
  red: {
    bg: `${colors.red}`,
    text: `${colors.red}`,
    border: `${colors.red}`,
    hoverBg: `${colors.red}`,
    hoverBorder: `${colors.red}`,
    hoverText: `${colors.red}`,
  },
};

export const breakPoint = {
  mobile: MOBILE_BREAKPOINT,
  ipad: IPAD_BREAKPOINT,
  tablet: TABLET_BREAKPOINT,
  smallLaptop: SMALL_LAPTOP_BREAKPOINT,
  laptop: LAPTOP_BREAKPOINT,
};

export const mediaQueryWidth = {
  laptopUp: `(min-width: ${breakPoint.laptop + 1}px)`,
  laptopDown: `(max-width: ${breakPoint.laptop}px)`,
  smallLaptopUp: `(min-width: ${breakPoint.smallLaptop + 1}px)`,
  smallLaptopDown: `(max-width: ${breakPoint.smallLaptop}px)`,
  tabletUp: `(min-width: ${breakPoint.tablet + 1}px)`,
  tablet: `(min-width: ${breakPoint.tablet + 1}px) and (max-width: ${breakPoint.laptop}px)`,
  tabletDown: `(max-width: ${breakPoint.tablet}px)`,
  ipadUp: `(min-width: ${breakPoint.ipad + 1}px)`,
  ipad: `(min-width: ${breakPoint.ipad + 1}px) and (max-width: ${breakPoint.tablet}px)`,
  ipadDown: `(max-width: ${breakPoint.ipad}px)`,
  mobileUp: `(min-width: ${breakPoint.mobile + 1}px)`,
  mobileDown: `(max-width: ${breakPoint.mobile}px)`,
};

const m = `@media only screen and`;
export const mediaQuery = {
  mobileDown: `${m} ${mediaQueryWidth.mobileDown}`,
  mobileUp: `${m} ${mediaQueryWidth.mobileUp}`,
  ipadDown: `${m} ${mediaQueryWidth.ipadDown}`,
  ipad: `${m} ${mediaQueryWidth.ipad}`,
  ipadUp: `${m} ${mediaQueryWidth.ipadUp}`,
  tabletDown: `${m} ${mediaQueryWidth.tabletDown}`,
  tablet: `${m} ${mediaQueryWidth.tablet}`,
  tabletUp: `${m} ${mediaQueryWidth.tabletUp}`,
  smallLaptopDown: `${m} ${mediaQueryWidth.smallLaptopDown}`,
  smallLaptopUp: `${m} ${mediaQueryWidth.smallLaptopUp}`,
  laptopDown: `${m} ${mediaQueryWidth.laptopDown}`,
  laptopUp: `${m} ${mediaQueryWidth.laptopUp}`,
};
